import Image from 'components/Image'
import Video from 'components/Video'
import NextSanityImage from 'components/NextSanityImage'

export default function Media({ media, imageLoadPriority, objectFit, useOldImage, ...props }) {
  if (!media) return null

  switch (media._type) {
    case 'imageAsset':
      return useOldImage ? (
        <Image image={media?.image} lazyLoadEffect={imageLoadPriority > 0} {...props} />
      ) : (
        <NextSanityImage
          image={media?.image}
          loadPriority={imageLoadPriority}
          objectFit={objectFit}
          {...props}
          quality={100}
        />
      )
    case 'videoAsset':
      return <Video src={media?.vimeoUrl} {...props} />
    default:
      return <></>
  }
}
