import Media from 'components/Media'
import { screens } from 'lib/constants'
import cx from 'classnames'
import useMedia from 'use-media'
import Overline from 'components/Overline'
import * as analytics from 'lib/analytics'
import getColorStyle from 'lib/getColorStyle'
import BlockContent from '@sanity/block-content-to-react'
import serializers from 'serializers/justLinks'
import { useState, useEffect } from 'react'
import IconGrid from './IconGrid'
import CustomButton from './CustomButton'
import DescriptionItems from './DescriptionItems'
import Disclaimer from './Disclaimer'
import { getSpacing } from './helpers'
import { useStore, } from 'context/globalStore'
import { updateSubscriptionLink } from '../../lib/abTestUtils'

export default function TwoUp({ module, settings, pageContext }) {
  const isScreenMedium = useMedia({ minWidth: screens.m })
  const isScreenLarge = useMedia({ minWidth: screens.l })
  const {
    headline,
    body,
    media,
    icons,
    desktopOrder,
    mobileOrder = 'bottom',
    disclaimerText,
    miniImage,
    fullWidthImage,
    isMediaHiddenOnMobile,
    bgColor,
    textColor,
    button,
    descriptionListItems
  } = module
  let { link } = module
  const _bgColor = getColorStyle(bgColor)
  const _textColor = getColorStyle(textColor)
  const isLinkButton = !button?.buttonType
  const iconGrid = IconGrid({ icons, isMediaHiddenOnMobile })
  const isScreen4K = useMedia({ minWidth: screens['4k'] })
  const [paddingTopMobile, setPaddingTopMobile] = useState(module?.spacingTopMobile ?? 20)
  const [paddingBottomMobile, setPaddingBottomMobile] = useState(module?.spacingBottomMobile ?? 20)
  const [paddingBottomTablet, setPaddingBottomTablet] = useState(module?.spacingBottomTablet ?? 25)
  const [paddingTopTablet, setPaddingTopTablet] = useState(module?.spacingTopTablet ?? 25)
  const [paddingBottomDesktop, setPaddingBottomDesktop] = useState(module?.spacingBottomDesktop ?? 30)
  const [paddingTopDesktop, setPaddingTopDesktop] = useState(module?.spacingTopDesktop ?? 30)
  const [paddingTop, setPaddingTop] = useState(0)
  const [paddingBottom, setPaddingBottom] = useState(0)
  const { store } = useStore()
  const { countryCode } = store
  const imageWidth = isScreenLarge ? 720 : (isScreenMedium ? 385 : 375);
  const imageHeight = isScreenLarge ? 449 : (isScreenMedium ? 385 : 500);

  useEffect(() => {
    module?.spacingTopMobile !== undefined && setPaddingTopMobile(module.spacingTopMobile)
    module?.spacingBottomMobile !== undefined && setPaddingBottomMobile(module.spacingBottomMobile)
    module?.spacingBottomTablet !== undefined && setPaddingBottomTablet(module.spacingBottomTablet)
    module?.spacingTopTablet !== undefined && setPaddingTopTablet(module.spacingTopTablet)
    module?.spacingBottomDesktop !== undefined && setPaddingBottomDesktop(module.spacingBottomDesktop)
    module?.spacingTopDesktop !== undefined && setPaddingTopDesktop(module.spacingTopDesktop)
    setPaddingTop(getSpacing(paddingTopMobile, paddingTopTablet, paddingTopDesktop))
    setPaddingBottom(getSpacing(paddingBottomMobile, paddingBottomTablet, paddingBottomDesktop))
  }, [module])

  if (countryCode !== 'US' && link?.reference?.slug === 'get-subscription') return null

  const isGetSubscriptionPage = pageContext?.navigation?.isGetSubscriptionPage
  link = updateSubscriptionLink(link, isGetSubscriptionPage)

  return (
    <div
      className={cx('relative', {
        'px-15 s:px-25 m:px-40 l:px-60 xl:px-80': !fullWidthImage,
        'm:px-0 ': fullWidthImage && desktopOrder === 'left',
        'm:px-0': fullWidthImage && desktopOrder === 'right',
      })}
      style={{ paddingTop: fullWidthImage ? '0px' : `${paddingTop}px`, paddingBottom: fullWidthImage ? '0px' : `${paddingBottom}px`, color: _textColor }}
    >
      <div className={cx('flex flex-col m:flex-row m:items-center  max-w-1440 mx-auto', {
        'justify-between l:justify-center' : !fullWidthImage
      })}>
        <div className={cx({
          'm:max-w-545': !fullWidthImage,
          'm:max-w-545 m:px-40 mx-auto absolute m:static top-24 left-0 px-16 text-center m:text-left z-2': fullWidthImage,
          'm:w-1/2': !miniImage,
          'm:w-full l:w-1/2': miniImage,
        })}>
          {module?.overline ? <Overline className="mb-25">{module.overline}</Overline> : null}
          <div className={cx( {
            'mb-16': !fullWidthImage,
            'm:mb-0': !icons?.length && !fullWidthImage,
            'text-center m:text-left': miniImage,
            'm:my-60': fullWidthImage
            })}>
            <h2 className={cx('leading-130', {
              'font-serif-modern text-24 m:text-32 l:text-40 mb-16 m:mb-24 font-medium m:font-normal': fullWidthImage,
              'text-24 m:text-32 font-medium mb-4 m:mb-8': !fullWidthImage,
            })}>
              {headline}
            </h2>
            {body ? (
              typeof body === 'string' ? (
                <p className="text-15 m:text-16">{body}</p>
              ) : (
                <div className="text-15 m:text-16">
                  <BlockContent blocks={body} serializers={serializers} />
                </div>
              )
            ) : null}
            <div className={cx({
              'm:hidden': icons?.length,
            })}>
              <CustomButton
                button={button}
                link={link}
                isLinkButton={isLinkButton}
                isScreenMedium={isScreenMedium}
                module={module}
                analytics={analytics}
              />
            </div>
            {isScreenMedium && descriptionListItems?.length ? <DescriptionItems descriptionListItems={descriptionListItems} /> : null}
          </div>
          {isScreenMedium && icons?.length ? iconGrid : null}
          <div className={cx({
              'hidden m:block mt-12 l:mt-22': icons?.length,
              'hidden': !icons?.length
            })}>
              <CustomButton
                button={button}
                link={link}
                isLinkButton={isLinkButton}
                isScreenMedium={isScreenMedium}
                module={module}
                analytics={analytics}
              />
            </div>
          {isScreenMedium && disclaimerText ? <Disclaimer disclaimerText={disclaimerText} /> : null}
        </div>
        <div
          className={cx('', {
            'm:w-1/2 h-full': !miniImage,
            'm:w-215 l:w-400 xxl:w-430 2k:w-500 flex-shrink-0': miniImage,
            'm:-order-1': desktopOrder === 'left',
            'm:ml-40 l:ml-60 xl:ml-80': desktopOrder !== 'left' && !fullWidthImage,
            'm:mr-40 l:mr-60 xl:mr-80': desktopOrder === 'left' && !fullWidthImage,
            '-order-1 mb-15': !isScreenMedium && mobileOrder === 'top',
          })}
        >
          <div
            className={cx('overflow-hidden', {
              'hidden m:block': isMediaHiddenOnMobile,
              'w-260 mx-auto m:mx-0 m:w-full': miniImage,
              'rounded': !fullWidthImage
            })}
          >
            <Media
              media={media}
              width={fullWidthImage ? imageWidth : 688}
              height={fullWidthImage ? imageHeight : 688}
              objectFit={'cover'}
              sizes={`92vw, (min-width: ${screens.m}) 50vw`}
              layout="responsive"
            />
          </div>
          {!isScreenMedium && icons?.length ? iconGrid : null}
          {!isScreenMedium && descriptionListItems?.length ? <DescriptionItems descriptionListItems={descriptionListItems} /> : null}
        </div>
      </div>
      {!isScreenMedium && disclaimerText ? <Disclaimer disclaimerText={disclaimerText} /> : null}
      {_bgColor ? (
        <span className="two-up-bg" style={{ '--tow-up-background': _bgColor }}></span>
      ) : null}
    </div>
  )
}
