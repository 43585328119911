import cx from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useIntersection } from 'use-intersection'

export default function Video({
  src,
  alt,
  width,
  height,
  fill = false,
  fit = 'cover',
  position = 'center',
  preload = false,
  paused = false,
  className,
}) {
  const ref = useRef()
  const intersecting = useIntersection(ref, { threshold: 0.01 })

  useEffect(() => {
    if (intersecting) {
      if (!paused) {
        ref.current?.play()
      }
    } else {
      ref.current?.pause()
    }
  }, [intersecting, paused])

  if (!src) return null
  return (
    <div
      className={cx(
        'relative z-1 overflow-hidden',
        {
          'h-0': !fill,
          'w-full h-full': fill,
        },
        className
      )}
      style={!fill ? { paddingTop: `${(height / width) * 100}%` } : null}
    >
      <div className="absolute inset-0">
        <video
          ref={ref}
          aria-label={alt || undefined}
          src={`${src}#t=0.1`}
          preload={preload ? 'auto' : 'none'}
          playsInline
          loop
          muted
          className={cx('w-full h-full', {
            'object-cover': fit === 'cover',
            'object-contain': fit === 'contain',
            'object-top': position === 'top',
            'object-bottom': position === 'bottom',
            'object-left': position === 'left',
            'object-right': position === 'right',
            'object-center': position === 'center',
          })}
        />
      </div>
    </div>
  )
}
